<template lang='pug'>
.w-100.bg-primary.text-white(style='min-height: 100% !important')
  .top.px-3(style='min-height: 100% !important')
    div(style='padding-top: 360px')
      .pf-display1.text-white.text-center Team Summaries
      .mt-2 Select your personal favourties in terms of quality and content - the best two will win a prize! Of course, "integrity" means that you dont' score yourself,even though you technically could...
  .bg-primary.px-3.pb-3
    .mt-2.bg-crimson.p-3(v-for='(question, i) in questions', :key='question.id')
      .pf-title.text-white Q{{ i + 1 }}
      .mt-1 {{ question.name }}
      .df-row-ac.mt-1(v-for='(userAnswer, j) in question.userAnswers', :key='userAnswer.no')
        .p-1.df-row-ac
          span #
          span {{ userAnswer.no }}
          .text-white.pf-subhead.ml-1.pt-2 *
        .ml-1.flex-1
          van-field(
            readonly,
            clickable,
            required,
            v-model='userAnswer.val',
            placeholder='Select a department',
            right-icon='arrow-down',
            @blur='checkPhone',
            :error-message='userAnswer.val == "" && showTip ? "This item is required" : ""',
            @click='handlePricker(question, userAnswer, i)'
          )
    .mt-3
      van-button(size='large', type='primary', color='#000000', @click='handleSave') Submit
  van-popup(v-model:show='showPicker', round, position='bottom')
    van-picker(
      title='标题',
      show-toolbar,
      :columns='options',
      @cancel='showPicker = false',
      :columns-field-names='customFieldName',
      @confirm='changeConfirm',
      :key='currentQuestion.id'
    )
</template>

<script>
import { Button, Popup, Picker, Field, Toast } from 'vant'
import API from '@/api/api'

export default {
  components: {
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Toast.name]: Toast
  },
  data() {
    return {
      showTip: false,
      index: 0,
      answers: [],
      currentQuestion: {},
      currentUserAnswer: {},
      options: [],
      userAnswers: [
        {
          no: 1,
          answer: '',
          val: ''
        },
        {
          no: 2,
          answer: '',
          val: ''
        },
        {
          no: 3,
          answer: '',
          val: ''
        }
      ],
      questions: [],
      customFieldName: {
        text: 'val',
        disabled: 'disabled'
      },
      showPicker: false
    }
  },
  methods: {
    loadQuestions() {
      API.question
        .list()
        .then((res) => {
          let data = res.data.data || []
          if (data && data.length > 0) {
            data.forEach((dt) => {
              dt.userAnswers =
                [
                  {
                    no: 1,
                    answer: '',
                    val: ''
                  },
                  {
                    no: 2,
                    answer: '',
                    val: ''
                  },
                  {
                    no: 3,
                    answer: '',
                    val: ''
                  }
                ] || []
            })
          }
          this.questions = data
          console.log('data===>', data)
        })
        .catch(() => {})
    },
    handleSave() {
      let filter = {}
      filter.answers = this.answers
      let showTip = false
      API.vote
        .create(filter)
        .then((res) => {
          if (res.data.ok) {
            if (res.data.data.id > 0) {
              showTip = false
              this.showTip = showTip
              this.$router.push({
                path: '/success'
              })
            } else {
              showTip = true
              Toast(res.data.data)
              this.showTip = showTip
            }
          }
        })
        .catch(() => {})
    },
    handlePricker(question, userAnswer, i) {
      console.log('option===>', userAnswer, i)
      this.currentQuestion = this.questions[i]
      this.currentUserAnswer = userAnswer
      this.options = question.options || []
      this.index = i
      this.showPicker = true
    },
    changeConfirm(selOption) {
      if (selOption) {
        if (
          this.questions[this.index] &&
          this.questions[this.index].userAnswers &&
          this.questions[this.index].userAnswers.length > 0
        ) {
          for (let j = 0; j < this.questions[this.index].userAnswers.length; j++) {
            if (this.questions[this.index].userAnswers[j].answer == selOption.key) {
              this.showPicker = false
              Toast('This option has been selected, please select again')
              return
            }
            if (this.questions[this.index].userAnswers[j].no == this.currentUserAnswer.no) {
              this.questions[this.index].userAnswers[j].answer = selOption.key
              this.questions[this.index].userAnswers[j].val = selOption.val
              break
            }
          }
        }
      }
      this.answers[this.index] = this.questions[this.index]
      this.showPicker = false
    }
  },
  mounted() {
    this.answers = []
    this.loadQuestions()
  }
}
</script>

<style lang="scss">
</style>
